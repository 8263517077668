export const csvDataVariable = [
    { lvl0: 'abrigos', lvl1: 'acolchados', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'acolchados', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'gabardinas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'gabardinas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'impermeables', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'impermeables', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'lana', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'lana', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'parkas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'parkas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'peacoat', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'peacoat', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'piel sintetica', lvl2: '', sexo: 'mujer' },
    { lvl0: 'abrigos', lvl1: 'piel sintetica', lvl2: '', sexo: 'hombre' },
    { lvl0: 'abrigos', lvl1: 'trench', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bañadores', lvl1: 'bikinies', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bañadores', lvl1: 'cortos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bañadores', lvl1: 'largos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bañadores', lvl1: 'una pieza', lvl2: '', sexo: 'mujer' },
    { lvl0: 'batines', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'batines', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'anillos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'anillos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'brazaletes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'colgantes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'colgantes', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'collares', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'collares', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'gemelos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'llaveros', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'llaveros', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'pendientes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'pendientes', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bisutería', lvl1: 'pulseras', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bisutería', lvl1: 'pulseras', lvl2: '', sexo: 'hombre' },
    { lvl0: 'blazers', lvl1: 'americanas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'blazers', lvl1: 'chalecos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'blazers', lvl1: 'chaquetas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'blazers', lvl1: 'chaquetas', lvl2: 'oversize', sexo: 'mujer' },
    { lvl0: 'blazers', lvl1: 'chaquetas', lvl2: 'lana', sexo: 'mujer' },
    { lvl0: 'blazers', lvl1: 'crop', lvl2: '', sexo: 'mujer' },
    { lvl0: 'blazers', lvl1: 'oversize', lvl2: '', sexo: 'hombre' },
    { lvl0: 'blazers', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bodys', lvl1: 'bandeau', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bodys', lvl1: 'encaje', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bodys', lvl1: 'manga corta', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bodys', lvl1: 'manga larga', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bodys', lvl1: 'tirantes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: 'bandoleras', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: 'clutch', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: 'de hombro', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: 'de mano', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: 'mochila', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: 'shopper', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bolsos', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'bufandas', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'bufandas', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'calcetines', lvl1: 'cortos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'calcetines', lvl1: 'cortos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'calcetines', lvl1: 'de estar por casa', lvl2: '', sexo: 'mujer' },
    { lvl0: 'calcetines', lvl1: 'de estar por casa', lvl2: '', sexo: 'hombre' },
    { lvl0: 'calcetines', lvl1: 'deportivos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'calcetines', lvl1: 'deportivos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'calcetines', lvl1: 'gordos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'calcetines', lvl1: 'gordos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'calcetines', lvl1: 'largos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'calcetines', lvl1: 'largos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'calcetines', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'calcetines', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: '', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'estampadas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'rayas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'lisa', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: '', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'lisas', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'lino', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'formal', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'cuadros', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'vaqueras', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'rayas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga corta', lvl2: 'rayas', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: '', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'satinadas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'lisa', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'estampadas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'rayas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'blusas', sexo: 'mujer' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: '', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'lisas', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'lino', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'formal', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'cuadros', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'vaqueras', sexo: 'hombre' },
    { lvl0: 'camisas', lvl1: 'manga larga', lvl2: 'rayas', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: '', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'oversize', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'deportivas', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'polos', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: '', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'lisas', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'deportivas', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'futbol', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga corta', lvl2: 'polos', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: '', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: 'polos', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: 'deporte', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: '', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: 'lisas', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: 'deportivas', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: 'futbol', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'manga larga', lvl2: 'polos', sexo: 'hombre' },
    { lvl0: 'camisetas', lvl1: 'tirantes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'camisetas', lvl1: 'tirantes', lvl2: 'deporte', sexo: 'mujer' },
    { lvl0: 'cardigans', lvl1: 'cremallera', lvl2: '', sexo: 'mujer' },
    { lvl0: 'cardigans', lvl1: 'cremallera', lvl2: '', sexo: 'hombre' },
    { lvl0: 'cardigans', lvl1: 'lana', lvl2: '', sexo: 'mujer' },
    { lvl0: 'cardigans', lvl1: 'lana', lvl2: '', sexo: 'hombre' },
    { lvl0: 'cardigans', lvl1: 'punto', lvl2: '', sexo: 'mujer' },
    { lvl0: 'cardigans', lvl1: 'punto', lvl2: '', sexo: 'hombre' },
    { lvl0: 'cardigans', lvl1: 'rayas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'cardigans', lvl1: 'rayas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'cardigans', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'cardigans', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'carteras', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'carteras', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chalecos', lvl1: 'acolchados', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chalecos', lvl1: 'acolchados', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chalecos', lvl1: 'punto', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chalecos', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chalecos', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chanclas', lvl1: 'de dedo', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chanclas', lvl1: 'de dedo', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chanclas', lvl1: 'de pala', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chanclas', lvl1: 'de pala', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chandals', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chandals', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'acolchadas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'acolchadas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'bomber', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'bomber', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'cortavientos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'cortavientos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'cuero', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'cuero', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'efecto piel', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'efecto piel', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'nieve', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'harrington', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'impermeables', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'nieve', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'piel', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'piel', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'polar', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'polar', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'punto', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'punto', lvl2: '', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'vaqueras', lvl2: 'oversize', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'vaqueras', lvl2: 'borrego', sexo: 'hombre' },
    { lvl0: 'chaquetas', lvl1: 'vaqueras', lvl2: '', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'vaqueras', lvl2: 'oversize', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'vaqueras', lvl2: 'borrego', sexo: 'mujer' },
    { lvl0: 'chaquetas', lvl1: 'worker', lvl2: '', sexo: 'hombre' },
    { lvl0: 'cinturones', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'cinturones', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'corbatas', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'corbatas', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'faldas', lvl1: 'acampanada', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'deportiva', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'faldas pantalon', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'midi', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'minifaldas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'satinadas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'tubo', lvl2: '', sexo: 'mujer' },
    { lvl0: 'faldas', lvl1: 'vaqueras', lvl2: '', sexo: 'mujer' },
    { lvl0: 'fulares', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gafas', lvl1: 'de sol', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gafas', lvl1: 'de sol', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gafas', lvl1: 'sin graduar', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gafas', lvl1: 'sin graduar', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gafas', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gafas', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gorras', lvl1: 'pana', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorras', lvl1: 'pana', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gorras', lvl1: 'planas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorras', lvl1: 'planas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gorras', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorras', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gorros', lvl1: 'boinas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorros', lvl1: 'boinas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gorros', lvl1: 'chapka', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorros', lvl1: 'docker', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorros', lvl1: 'lana', lvl2: '', sexo: 'mujer' },
    { lvl0: 'gorros', lvl1: 'lana', lvl2: '', sexo: 'hombre' },
    { lvl0: 'gorros', lvl1: 'pelo', lvl2: '', sexo: 'mujer' },
    { lvl0: 'guantes', lvl1: 'cuero', lvl2: '', sexo: 'mujer' },
    { lvl0: 'guantes', lvl1: 'cuero', lvl2: '', sexo: 'hombre' },
    { lvl0: 'guantes', lvl1: 'lana', lvl2: '', sexo: 'mujer' },
    { lvl0: 'guantes', lvl1: 'lana', lvl2: '', sexo: 'hombre' },
    { lvl0: 'guantes', lvl1: 'nieve', lvl2: '', sexo: 'mujer' },
    { lvl0: 'guantes', lvl1: 'nieve', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'cashmere', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'cashmere', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'cuello alto', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'cuello alto', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'cuello cremallera', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'cuello cremallera', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'cuello pico', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'cuello pico', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'cuello redondo', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'cuello redondo', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'estampados', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'estampados', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'lana', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'lana', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'manga corta', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'oversize', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'oversize', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'punto', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'punto', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'punto fino', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'punto fino', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: 'rayas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: 'rayas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'jerseys', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'jerseys', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'leggings', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'babydoll', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'body', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'corsé', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'de encaje', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'deportiva', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'sujetadores', lvl2: '', sexo: 'mujer' },
    { lvl0: 'lencería', lvl1: 'sujetadores', lvl2: 'deportivos', sexo: 'mujer' },
    { lvl0: 'mochilas', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'mochilas', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'monederos', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'monos', lvl1: 'corto', lvl2: '', sexo: 'mujer' },
    { lvl0: 'monos', lvl1: 'corto', lvl2: 'vaqueros', sexo: 'mujer' },
    { lvl0: 'monos', lvl1: 'largo', lvl2: '', sexo: 'mujer' },
    { lvl0: 'monos', lvl1: 'largo', lvl2: 'vaqueros', sexo: 'mujer' },
    { lvl0: 'monos', lvl1: 'moto', lvl2: '', sexo: 'hombre' },
    { lvl0: 'monos', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'orejeras', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'orejeras', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'bermudas', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'chandal', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'shorts', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'deporte', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'cargo', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'bermudas', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'chandal', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'cortos', lvl2: 'cargo', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'cargo', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'anchos', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'pana', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'nieve', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'de vestir', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'pinzas', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'joggers', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'deporte', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'cropped', sexo: 'mujer' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'cargo', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'joggers', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'pana', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'chinos', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'pinzas', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'de vestir', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'lino', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'chandal', sexo: 'hombre' },
    { lvl0: 'pantalones', lvl1: 'largos', lvl2: 'nieve', sexo: 'hombre' },
    { lvl0: 'pañuelos', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'pijamas', lvl1: 'cortos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'pijamas', lvl1: 'cortos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'pijamas', lvl1: 'largos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'pijamas', lvl1: 'largos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'pijamas', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'pijamas', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'relojes', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'relojes', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'riñoneras', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'riñoneras', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sombreros', lvl1: 'ala ancha', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sombreros', lvl1: 'bombin', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sombreros', lvl1: 'canotier', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sombreros', lvl1: 'cloche', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sombreros', lvl1: 'fedora', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sombreros', lvl1: 'fedora', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sombreros', lvl1: 'pamela', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sombreros', lvl1: 'panamá', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sombreros', lvl1: 'trilby', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sombreros', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sudaderas', lvl1: 'con capucha', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sudaderas', lvl1: 'con capucha', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sudaderas', lvl1: 'con cremallera', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sudaderas', lvl1: 'con cremallera', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sudaderas', lvl1: 'cuello cremallera', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sudaderas', lvl1: 'cuello cremallera', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sudaderas', lvl1: 'deportiva', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sudaderas', lvl1: 'manga corta', lvl2: '', sexo: 'hombre' },
    { lvl0: 'sudaderas', lvl1: 'sin capucha', lvl2: '', sexo: 'mujer' },
    { lvl0: 'sudaderas', lvl1: 'sin capucha', lvl2: '', sexo: 'hombre' },
    { lvl0: 'tops', lvl1: 'con tirantes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'tops', lvl1: 'de encaje', lvl2: '', sexo: 'mujer' },
    { lvl0: 'tops', lvl1: 'halter', lvl2: '', sexo: 'mujer' },
    { lvl0: 'tops', lvl1: 'sin tirantes', lvl2: '', sexo: 'mujer' },
    { lvl0: 'tops', lvl1: 'topcrops', lvl2: '', sexo: 'mujer' },
    { lvl0: 'trajes', lvl1: 'elegantes', lvl2: '', sexo: 'hombre' },
    { lvl0: 'trajes', lvl1: 'lino', lvl2: '', sexo: 'mujer' },
    { lvl0: 'trajes', lvl1: 'lino', lvl2: '', sexo: 'hombre' },
    { lvl0: 'trajes', lvl1: 'terciopelo', lvl2: '', sexo: 'mujer' },
    { lvl0: 'trajes', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'trajes', lvl1: '', lvl2: '', sexo: 'hombre' },
    { lvl0: 'tunicas', lvl1: '', lvl2: '', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'cortos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'cortos', lvl2: 'bermudas', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'cortos', lvl2: 'shorts', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'tiro alto', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'tiro medio', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'anchos', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'rectos', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'flare', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'skinny', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'baggy', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'rotos', sexo: 'mujer' },
    { lvl0: 'vaqueros', lvl1: 'cortos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'slim', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'straight', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'baggy', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'flare', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'skinny', sexo: 'hombre' },
    { lvl0: 'vaqueros', lvl1: 'largos', lvl2: 'anchos', sexo: 'hombre' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'sin mangas', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'manga larga', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'manga corta', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'satinados', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'deportivos', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'invierno', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'verano', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'cortos', lvl2: 'vaqueros', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'invierno', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'verano', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'sin mangas', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'manga larga', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'manga corta', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'satinados', sexo: 'mujer' },
    { lvl0: 'vestidos', lvl1: 'largos', lvl2: 'vaqueros', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'baloncesto', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'de vestir', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'de vestir', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'deportivas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'deportivas', lvl2: 'running', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'deportivas', lvl2: 'senderismo', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'deportivas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'deportivas', lvl2: 'running', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'deportivas', lvl2: 'senderismo', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'futbol', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'futbol sala', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'piel', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'piel', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatillas', lvl1: 'skate', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatillas', lvl1: 'skate', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'bailarinas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'botas altas', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'nieve', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'agua', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'ugg', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'nieve', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'agua', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'botas', lvl2: 'moto', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'botines', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'crocs', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'crocs', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'mocasines', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'mocasines', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'náuticos', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'náuticos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'piel', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'piel', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'planos', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'sandalias', lvl2: '', sexo: 'mujer' },
    { lvl0: 'zapatos', lvl1: 'sandalias', lvl2: '', sexo: 'hombre' },
    { lvl0: 'zapatos', lvl1: 'tacon', lvl2: '', sexo: 'mujer' },
  ];