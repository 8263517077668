import algoliasearch from 'algoliasearch';
import { history } from 'instantsearch.js/es/lib/routers';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const SEARCH_API_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;

export const searchClient = algoliasearch(APPLICATION_ID, SEARCH_API_KEY);

// routing.js
export const routing = {
  router: history(),

  stateMapping: {
    stateToRoute(uiState) {
      const indexUiState = uiState['productos_erastro'];
      const hierarchicalMenu = indexUiState.hierarchicalMenu || {};

      // Aplanar la estructura de hierarchicalMenu
      const flattenedHierarchicalMenu = {};
      Object.keys(hierarchicalMenu).forEach((key) => {
        if (Array.isArray(hierarchicalMenu[key])) {
          flattenedHierarchicalMenu[key] = hierarchicalMenu[key].join(',');
        }
      });

      return {
        query: indexUiState.query,
        ...flattenedHierarchicalMenu, // Incluir los niveles aplanados
        page: indexUiState.page,
        clothe: indexUiState.refinementList?.clothe,
        category: indexUiState.refinementList?.category,
        brand: indexUiState.refinementList?.brand,
        sex: indexUiState.refinementList?.sex,
        web: indexUiState.refinementList?.web,
        size: indexUiState.refinementList?.size,
        condition: indexUiState.refinementList?.condition,
        color: indexUiState.refinementList?.color,
        city: indexUiState.refinementList?.city,
      };
    },

    routeToState(routeState) {
      const hierarchicalMenu = {};

      // Reconstruir la estructura de hierarchicalMenu
      Object.keys(routeState).forEach((key) => {
        if (key.startsWith('hierarchicalCategories.lvl')) {
          hierarchicalMenu[key] = routeState[key].split(',');
        }
      });

      return {
        productos_erastro: {
          query: routeState.query,
          hierarchicalMenu,
          refinementList: {
            clothe: routeState.clothe,
            sex: routeState.sex,
            web: routeState.web,
            size: routeState.size,
            condition: routeState.condition,
            color: routeState.color,
            city: routeState.city,
            brand: routeState.brand,
            category: routeState.category,
          },
          page: routeState.page,
        },
      };
    },
  },
};
