import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthProvider } from "../AuthProvider/AuthProvider";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import "./AfiliadosDashboard.css"; // Asegúrate de crear este archivo para los estilos

export const AfiliadosDashboard = () => {
  const [affiliateData, setAffiliateData] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [startDate, setStartDate] = useState(""); // Fecha de inicio
  const [endDate, setEndDate] = useState(""); // Fecha de fin
  const [filteredData, setFilteredData] = useState([]);
  const [totalRegistrations, setTotalRegistrations] = useState(0);
  const [commission, setCommission] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    // Establecer las fechas por defecto al cargar el componente
    const setDefaultDates = () => {
        const today = new Date();
        
        const firstDayOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDayOfCurrentMonth.setDate(firstDayOfCurrentMonth.getDate() + 1);
        const formattedStartDate = firstDayOfCurrentMonth.toISOString().split("T")[0]; // YYYY-MM-DD
      
        const formattedEndDate = today.toISOString().split("T")[0]; // YYYY-MM-DD
      
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      };

    setDefaultDates();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentUser && currentUser.affiliateCoupon) {
          const response = await fetch(
            `https://us-central1-erastro-326a1.cloudfunctions.net/getAffiliateData?couponId=${currentUser.affiliateCoupon}`
          );
          if (!response.ok) {
            throw new Error("Error al obtener los datos de afiliados");
          }
          const data = await response.json();
          setAffiliateData(data.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (currentUser && currentUser.affiliate) {
      fetchData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (affiliateData) {
      filterDataByDateRange();
    }
  }, [affiliateData, startDate, endDate]);

  const removeDuplicates = (data) => {
    return data.filter((value, index, self) => 
      index === self.findIndex((t) => (
        t.date === value.date
      ))
    );
  };

  const filterDataByDateRange = () => {
    const registrations = affiliateData.customerRegistrations;

    // Eliminar duplicados
    const uniqueRegistrations = removeDuplicates(registrations);

    // Filtrar registros dentro del rango de fechas
    const filtered = uniqueRegistrations.filter((registration) => {
      const date = new Date(registration.date);
      return date >= new Date(startDate) && date <= new Date(endDate);
    });

    // Agrupar los registros por fecha (similar a lo que se hacía con los periodos de tiempo)
    const groupedData = {};
    filtered.forEach((registration) => {
      const date = new Date(registration.date);
      const key = date.toISOString().split("T")[0]; // Agrupar por día (YYYY-MM-DD)

      if (!groupedData[key]) {
        groupedData[key] = 0;
      }
      groupedData[key]++;
    });

    // Convertir a un array para el gráfico
    const chartData = Object.entries(groupedData).map(([key, value]) => ({
      name: key,
      registros: value,
    }));

    // Ordenar de menos reciente a más reciente
    chartData.sort((a, b) => new Date(a.name) - new Date(b.name));

    // Calcular el total de registros y la comisión
    const total = chartData.reduce((sum, entry) => sum + entry.registros, 0);
    setTotalRegistrations(total);
    setCommission(total * 1.5);

    setFilteredData(chartData);
  };

  const handleUserLoggIn = (user) => {
    setCurrentUser(user);
  };

  const handleUserNotRegistered = () => {
    navigate("/login");
  };

  const handleUserNotLoggIn = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (currentUser && !currentUser.affiliate) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  if (currentUser && currentUser.affiliate) {
    return (
      <div className="afiliados-dashboard">
        <h1>Dashboard de Afiliados</h1>

        {/* Selector de rango de fechas */}
        <div className="date-range-selector">
          <label>Fecha de inicio:</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <label>Fecha de fin:</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>

        {/* Gráfico */}
        <div className="charts-section">
          <h2>Registros de Usuarios</h2>
          {filteredData.length === 0 ? (
            <div className="no-records-message">
                <p>No hay registros para el periodo seleccionado</p>
            </div>
            ) : (
            <ResponsiveContainer width="100%" height={500}>
                <BarChart data={filteredData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="registros" fill="#8884d8" />
                </BarChart>
            </ResponsiveContainer>
            )}
        </div>

        {/* Registros y comisión */}
        <div className="commission-section">
          <h2>Resumen</h2>
          <p>Total de registros: {totalRegistrations}</p>
          <p>Comisión: €{commission.toFixed(2)}</p>
        </div>
      </div>
    );
  }

  return (
    <AuthProvider
      onUserLoggIn={handleUserLoggIn}
      onUserNotLoggIn={handleUserNotLoggIn}
      onUserNotRegistered={handleUserNotRegistered}
    ></AuthProvider>
  );
};
