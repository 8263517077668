import { fecha } from '../utils/utils'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDoc, getFirestore, doc, query, collection, where, getDocs, setDoc, updateDoc, arrayUnion, arrayRemove, deleteDoc, addDoc, onSnapshot, orderBy, serverTimestamp, or, and} from "firebase/firestore"
import {getAuth, deleteUser, reauthenticateWithPopup, GoogleAuthProvider} from "firebase/auth"
import { getStorage} from "firebase/storage"
import 'firebase/functions';
// setStorage, ref, uploadBytes, getDownloadURL, getBytes,
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app)

export async function userExists(uid){
  const docRef = doc(db, 'users', uid);
  const res = await getDoc(docRef);
  return res.exists();
}

export async function usernameExists(username){
    const users = [];
    const docsRef = collection(db, 'users');
    const q = query(docsRef, where('username', '==', username));
    const querySnapshot = await getDocs(q)

    querySnapshot.forEach(doc =>{
      users.push(doc.data())
    })
    return users.length > 0 ? users[0].uid : null
}

export async function registerNewUser(user){
    try {
      const collectionRef = collection(db, 'users');
      const docRef = doc(collectionRef, user.uid);
      await setDoc(docRef, user);
    } catch (error) {
      
    }
}

export async function updateUser(user){
    try {
      const collectionRef = collection(db, 'users');
      const docRef = doc(collectionRef, user.uid);
      await setDoc(docRef, user);
    } catch (error) {
      
    }
}

export async function getUserInfo(uid){
  try {
    const docRef = doc(db, 'users', uid);
    const document = await getDoc(docRef);
    return document.data();
  } catch (error) {
    
  }
}

export async function updateLastLogin(userId) {
  if (!userId) return;

  try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, {
          lastLogin: serverTimestamp()
      });
  } catch (error) {
      console.error("Error al actualizar la última conexión:", error);
  }
}

export async function addToFavorites(uid, productId) {
  // añadir productos a usuarios
  const userDocRef = doc(db, 'users', uid);

  // Utiliza la función arrayUnion para añadir el elemento al array de favoritos
  await updateDoc(userDocRef, {
    likes: arrayUnion(productId),
  });
  // añadir usuarios a productos
  const productDocRef = doc(db, 'productos', productId);

  await updateDoc(productDocRef, {
    likes: arrayUnion(uid),
  });
  // Actualizar el campo n_likes

  const productSnapshot = await getDoc(productDocRef);
  const likesCount = productSnapshot.data().likes.length;
  
  await updateDoc(productDocRef, {
    n_likes: likesCount,
  });

}

// Función para eliminar un elemento del array en Firestore
export async function removeFromFavorites(uid, productId) {
  // eliminar productos de usuarios
  const userDocRef = doc(db, 'users', uid);

  // Utiliza la función arrayRemove para eliminar el elemento del array de favoritos
  await updateDoc(userDocRef, {
    likes: arrayRemove(productId),
  });

  // eliminar usuarios de productos
  const productDocRef = doc(db, 'productos', productId);

  await updateDoc(productDocRef, {
    likes: arrayRemove(uid),
  });

  // Actualizar el campo n_likes

  const productSnapshot = await getDoc(productDocRef);
  const likesCount = productSnapshot.data().likes.length;
  
  await updateDoc(productDocRef, {
    n_likes: likesCount,
  });
}


export async function getProductById(id) {
  try {
    // Obtiene el documento correspondiente al ID en la colección "productos"
    const productDoc = await getDoc(doc(db, 'productos', id));

    if (productDoc.exists()) {
      // Retorna la información del producto si existe
      return {
        id: productDoc.id,
        ...productDoc.data(),
      };
    } else {
      // Puedes manejar el caso en el que el producto no existe según tus necesidades
      console.warn(`Producto con ID ${id} no encontrado.`);
      return null;
    }
  } catch (error) {
    console.error('Error al obtener producto por ID:', error);
    return null;
  }
}

export async function getFavoritesByUserId(ids) {
  const products = [];

  try {
    // Itera sobre cada ID y obtén la información del producto
    for (const id of ids) {
      const product = await getProductById(id);
      if (product) {
        products.push(product);
      }
    }
  } catch (error) {
  }

  return products;
}

export async function borrarUsuario() {
  try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (!user) {
          console.log("No hay usuario autenticado para eliminar.");
          return;
      }

      // Reautenticar al usuario con Google antes de eliminar
      const provider = new GoogleAuthProvider();
      await reauthenticateWithPopup(user, provider);

      // Obtener información del usuario en Firestore antes de eliminarlo
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
          const userData = userSnap.data();

          // Guardar datos en "deletedUsers" con timestamp de eliminación
          const deletedUserRef = doc(db, "deletedUsers", user.uid);
          await setDoc(deletedUserRef, {
              ...userData,
              deletedAt: new Date(), // Agrega la fecha de eliminación
              deletedBy: user.email // Guarda quién eliminó la cuenta
          });

          // Eliminar usuario de Firestore
          await deleteDoc(userRef);
      } else {
          console.log("El usuario no tenía datos en Firestore.");
      }

      // Eliminar usuario de Firebase Authentication
      await deleteUser(user);

      console.log("Usuario eliminado correctamente y movido a deletedUsers.");
  } catch (error) {
      console.error("Error al eliminar usuario:", error);
  }
}
// Alertas ==================================================================================================

export async function addAlert(uid, alert, isPremium) {
  try {
    const alertsRef = collection(db, `users/${uid}/alerts`);
    
    // Consulta la cantidad de alertas actuales
    const q = query(alertsRef);
    const querySnapshot = await getDocs(q);
    const alertCount = querySnapshot.size;

    if (!isPremium && alertCount >= 1) {
      throw new Error("Los usuarios gratuitos solo pueden tener una alerta.");
    }

    if (isPremium && alertCount >= 100) {
      throw new Error("Los usuarios premium solo pueden tener un máximo de 100 alertas.");
    }

    // Añadir la nueva alerta
    const docRef = await addDoc(alertsRef, alert);
    return docRef.id;
  } catch (error) {
    throw error;
  }
}

export async function getAlertsByUserId(uid) {
  const docRef = collection(db, "users", uid, "alerts");
  const querySnapshot = await getDocs(docRef);
  const alerts = []; // Creamos un arreglo para almacenar las alertas

  querySnapshot.forEach((doc) => {
    // Agregamos cada alerta al arreglo
    alerts.push({
      id: doc.id,
      data: doc.data()
    });
  });

  alerts.sort((a, b) => {
    const dateA = a.data.creationDate;
    const dateB = b.data.creationDate;

    // Orden descendente (más reciente primero)
    return dateB - dateA;
  });

  return alerts; // Retornamos el arreglo de alertas
};

export async function handleDeleteAlert(uid, alertId) {
  try {
    // Construir la referencia al documento de la alerta
    const alertRef = doc(db, 'users', uid, 'alerts', alertId);
    // Eliminar el documento de la base de datos
    await deleteDoc(alertRef);
  } catch (error) {
    throw error; // Propaga el error para manejarlo en otro lugar si es necesario
  }
}

export async function getAlertProducts(json) {
  try {
    let fechaActual = new Date();
    let haceUnaSemana = new Date(fechaActual);
    haceUnaSemana.setDate(fechaActual.getDate() - 100);

    const docRef = collection(db, 'productos');
    let q = query(docRef);
    
    // Filtro por categorías jerárquicas según los niveles disponibles
    if (json.hierarchicalCategories?.lvl2) {
      q = query(q, where('hierarchicalCategories.lvl2', '==', json.hierarchicalCategories.lvl2));
    } else if (json.hierarchicalCategories?.lvl1) {
      q = query(q, where('hierarchicalCategories.lvl1', '==', json.hierarchicalCategories.lvl1));
    } else if (json.hierarchicalCategories?.lvl0) {
      q = query(q, where('hierarchicalCategories.lvl0', '==', json.hierarchicalCategories.lvl0));
    }
    
    if (json.sexo !== '') {
      q = query(q, where('sex', '==', json.sexo));
    }
    if (json.talla !== '') {
      q = query(q, where('size', '==', json.talla));
    }
    if (json.estado !== '') {
      q = query(q, where('condition', '==', json.estado));
    }
    if (json.marca !== '') {
      q = query(q, where('brand', '==', json.marca));
    }
    if (json.color !== '') {
      q = query(q, where('color', '==', json.color));
    }
    if (json.ubicacion !== '') {
      q = query(q, where('city', '==', json.ubicacion));
    }
    if (json.precioMax !== '') {
      q = query(q, where('price', '<=', parseInt(json.precioMax)));
    }
    
    q = query(q, where('scraped_date', '>=', haceUnaSemana));

    const querySnapshot = await getDocs(q);
    const products = [];
    querySnapshot.forEach(doc => {
      products.push({ id: doc.id, ...doc.data() });
    });
    return products;
  } catch (error) {
    console.error('Error al obtener los productos:', error);
    throw error;
  }
}

export const markNotificationAsSeen = async (userId, alertId) => {
  if (!userId || !alertId) return;

  try {
    const alertaRef = doc(db, `users/${userId}/alerts`, alertId);
    await updateDoc(alertaRef, { notificationState: false });
  } catch (error) {
    console.error("Error actualizando notificationState:", error);
  }
};
// STRIPE =================================================================================================

export async function getStripePlans() {
  const q = query(collection(db, 'planes'), where('active', '==', true));
  const querySnapshot = await getDocs(q);
  
  const planes = {}
  await Promise.all(querySnapshot.docs.map(async function (doc) {
    planes[doc.id] = doc.data()
    const priceSnap = await getDocs(collection(doc.ref, 'prices'));
    priceSnap.forEach((priceDoc) => {
      planes[doc.id].prices = {
        priceId: priceDoc.id,
        priceData: priceDoc.data()
      }
    });
  }));
  
  return planes
}


export async function pay(currentUser, priceId) {
  try {
    // Añadir un nuevo documento con la información de la sesión de pago
    const docRef = await addDoc(collection(db, `users/${currentUser.uid}/checkout_sessions`), {
      price: priceId,
      allow_promotion_codes: true,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });

    // Crear una promesa que resuelva cuando se obtiene la URL de Stripe
    const stripeUrlPromise = new Promise((resolve, reject) => {
      const unsubscribe = onSnapshot(docRef, (snap) => {
        const { error, url } = snap.data();
        if (error) {
          reject(new Error(`An error occurred: ${error.message}`));
        }
        if (url) {
          resolve(url);
          unsubscribe(); // Cancelar la suscripción después de recibir la URL
        }
      });
    });

    // Esperar la resolución de la promesa para obtener la URL de Stripe
    const stripeUrl = await stripeUrlPromise;
    
    // Redirigir al usuario a la URL de Stripe Checkout
    window.location.assign(stripeUrl);
  } catch (error) {
    // Manejar cualquier error que ocurra durante el proceso
    console.error('Payment error:', error.message);
    alert('Payment failed. Please try again later.');
  }
}


export async function getSubscriptionStatus(uid) {
  const subscriptionsRef = collection(db, "users", uid, "subscriptions");
  const q = query(subscriptionsRef, orderBy("created", "desc"));
  const querySnapshot = await getDocs(q);
  const subscriptions = [];

  querySnapshot.forEach((doc) => {
    subscriptions.push({
      id: doc.id,
      data: doc.data()
    });
  });

  const lastSubscription = subscriptions[0]; // Ahora las suscripciones ya están ordenadas, así que tomamos la primera
  let isPremium = false;
  if (lastSubscription && lastSubscription.data.status === 'active') {
    isPremium = true;
  }

  return isPremium;
};

export async function goStripePortal() {
const functionRef = app
  .app()
  .functions('europe-west1')
  .httpsCallable('ext-firestore-stripe-payments-createPortalLink');
const { data } = await functionRef({
  returnUrl: window.location.origin,
  locale: "auto", // Optional, defaults to "auto"
  configuration: process.env.REACT_APP_STRIPE_CONFIG, // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
});
window.location.assign(data.url);
}

// LOGS PRODUCTO =================================================================================================

export async function guardarLogProducto(data) {
  try {
    const docRef = await addDoc(collection(db, 'logs_producto'), {
      ...data,
      timestamp: serverTimestamp(), // Guarda la hora del evento automáticamente
    });
  } catch (error) {
    console.error(error);
  }
}