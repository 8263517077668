import Producto from '../Producto/Producto';
import './Productos.css';
import { AuthProvider } from '../AuthProvider/AuthProvider'
import { Compartir } from '../Compartir/Compartir';
import { searchClient, routing } from '../../firebase/algolia';
import { InstantSearch, SearchBox, Hits, RefinementList, Pagination, useInstantSearch, RangeInput, ClearRefinements, SortBy, Stats, HierarchicalMenu } from 'react-instantsearch';
import { upperFL } from '../../utils/utils'
import React, {useState, useEffect}from 'react'
import { getSubscriptionStatus} from '../../firebase/config'
import { AdsenseDisplay } from '../AdsenseDisplay/AdsenseDisplay';

export const Productos = () => {

  /*
    Stages:
    0: initiated
    1: loading
    2: login completed
    3: login but no username
    4: not logged
    5: ya existe el username
    6: nuevo username: click para continuar
    7: premium
    */
  
    const [state, setState] = useState(0);
    const [currentUser, setCurrentUser] = useState({})
    const [premium, setPremium] = useState([]);
    const [showFilters, setShowFilters] = useState(false);
    const palabrasProhibidas = ["desconocido", "otro", "sin especificar", "desconocida"];

    useEffect(() => {
      const fetchSubs = async () => {
        try {
          if (currentUser && currentUser.uid) {
            const isPremium = await getSubscriptionStatus(currentUser.uid);
            setPremium(isPremium);
          }
        } catch (error) {
          console.error('Error al obtener la subscripción:', error);
        }
      };
    
      fetchSubs();
    }, [currentUser]);

    useEffect(() => {
      // Este efecto se ejecutará cada vez que currentUser cambie
      if (premium === true) {
        setState(7);
      }
    }, [currentUser, premium]);
  
  
    function handleUserPremium(user){
      setCurrentUser(user)
      setState(7)
      
    }
  
    function handleUserLoggIn(user){
        setCurrentUser(user)
        setState(2)
    }
  
    function handleUserNotRegistered(user){
        setState(4)
    }
  
    function handleUserNotLoggIn(){
        setState(4)
    }

    function formatUrlParameters(url) {
      const params = new URLSearchParams(url.search);
  
      const query = params.getAll('query').join(', ');
      const categories = params.getAll('hierarchicalCategories.lvl0').join(' ');
      const sex = params.getAll('sex[0]').join(', ');
      const web = params.getAll('web[0]').join(', ');
      const size = params.getAll('size[0]').join(', ');
      const condition = params.getAll('condition[0]').join(', ');
      const color = params.getAll('color[0]').join(', ');
      const city = params.getAll('city[0]').join(', ');
      const brand = params.getAll('brand[0]').join(', ');
  
      // Separar los niveles de categorías por comas  
      let title = '';
      if (query !== '') {
          title += `${query} `;
      } 
      if (categories !== '') {
          title += `${categories}`;
      } else {
          if (query === "") {
              title += 'Ropa de segunda mano';
          }
      }
      if (sex !== '') {
          title += ` de ${sex}`;
      }
      if (size !== '') {
          title += `, talla: ${size}`;
      }
      if (condition !== '') {
          title += `, ${condition}`;
      }
      if (color !== '') {
          title += `, color: ${color}`;
      }
      if (city !== '') {
          title += ` en ${city}`;
      }
      if (web !== '') {
          title += `, ${web}`;
      }
      if (brand !== '') {
          title += `, marca: ${brand}`;
      }
  
      return upperFL(title || 'Ropa de segunda mano');
  }
  

  const currentUrl = window.location.href;
  const exampleUrl = new URL(currentUrl);
  const formattedResult = formatUrlParameters(exampleUrl);
  const title = formattedResult;

  // No results
  function NoResultsBoundary({ children, fallback }) {
    const { results } = useInstantSearch();

    if (!results.__isArtificial && results.nbHits === 0) {
      return (
        <>
          {fallback}
          <div hidden>{children}</div>
        </>
      );
    }

    return children;
  }

  function NoResults() {
    const { indexUiState } = useInstantSearch();

    return (
      <div>
        <p>
          No results for <q>{indexUiState.query}</q>.
        </p>
      </div>
    );
  }

  useEffect(() => {
    if (showFilters) {
      document.body.style.overflow = 'hidden'; // Bloquea el desplazamiento del fondo
    } else {
      document.body.style.overflow = ''; // Restaura el desplazamiento
    }
    return () => {
      document.body.style.overflow = ''; // Limpia el efecto al desmontar
    };
  }, [showFilters]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    // Verifica si el usuario es premium
    if (state === 7) {
      // Aplica la regla CSS para ocultar los anuncios
      const style = document.createElement('style');
      style.innerHTML = `
        /* Ocultar todos los anuncios de Google */
        .adsbygoogle, 
        iframe[src*="googlesyndication"], 
        div[id*="google_ads"], 
        div[class*="adsense"], 
        div[class*="google_ads_iframe"] {
          display: none !important;
        }
      `;
      document.head.appendChild(style);
      console.log('Anuncios ocultados para usuarios premium');
    }
  }, [state]);
  
  
  // Borrar palabras
  useEffect(() => {
    const palabrasProhibidas = [
      'desconocido', 
      'otro',
      'sin especificar',
      'desconocida',
      'color',
      'local',
      'vintage',
      'sin marca',
      'ubicacion',
      'es',
      'spain',
      'one size',
      'talla única'
    ];
  
    const hideItems = () => {
      const items = document.querySelectorAll('.ais-RefinementList-item');
      items.forEach(item => {
        const labelText = item.querySelector('.ais-RefinementList-labelText');
        if (labelText) {
          const texto = labelText.textContent.trim().toLowerCase();
          if (palabrasProhibidas.some(palabra => texto.includes(palabra.toLowerCase()))) {
            item.style.display = 'none';
          }
        }
      });
    };
  
    // Ejecutar inmediatamente y luego cada 500ms
    hideItems();
    const interval = setInterval(hideItems, 500);
    
    return () => clearInterval(interval);
  }, []);

  if(state===4){
    return (

    <div>
      <InstantSearch indexName="productos_erastro" searchClient={searchClient} routing={routing}>
        <div className="search-container">
            <SearchBox placeholder={"Buscar producto"} className="buscador-principal" />
        </div>

        <div className="container">
            {/* Botón de mostrar filtros */}
            <button className="toggle-filters-button" onClick={toggleFilters}>
              {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            </button>

            {/* Superposición para cerrar filtros */}
            {showFilters && <div className="overlay" onClick={toggleFilters}></div>}

            {/* Contenedor de filtros */}
            <div className={`filter-container ${showFilters ? 'visible' : ''}`}>
              {/* Botón de cerrar filtros (solo visible en móviles) */}
              <button className="close-filters-button" onClick={toggleFilters}>
                ✕ Cerrar
              </button>
              <div className="filter">
                <ClearRefinements translations={{ resetButtonText: 'Borrar filtros' }} />
                <h3>Sexo</h3>
                <RefinementList attribute="sex" />
                <h3>Categorías</h3>
                <HierarchicalMenu limit={10} showMore={true}  showMoreLimit={40}
                  attributes={[
                    "hierarchicalCategories.lvl0",  // Categoría principal (ej. "Camisetas")
                    "hierarchicalCategories.lvl1",  // Subcategoría (ej. "Camisetas > Manga Corta")
                    "hierarchicalCategories.lvl2"   
                  ]}
                />
                <h3>Precio</h3>
                <RangeInput attribute="price" /> 
                <h3>Talla</h3>
                <RefinementList attribute="size" limit={7} showMore={true} searchable={true} />
                <h3>Estado</h3>
                <RefinementList attribute="condition" limit={5} />
                <h3>Color</h3>
                <RefinementList attribute="color" limit={5} showMore={true} searchable={true} />
                <h3>Marca</h3>
                <RefinementList attribute="brand" limit={5} showMore={true} searchable={true} />
                <h3>Ubicación</h3>
                <RefinementList attribute="city" limit={5} showMore={true} searchable={true} />
              </div>
            </div>

            <div className='productos'>
              <NoResultsBoundary fallback={<NoResults />}>
              <h1>{title}</h1>
              <p> Descubre las mejores oportunidades en tiendas online y ahorra como nunca antes.</p>
              <Compartir></Compartir>
              <AdsenseDisplay dataAdSlot='7231057135'/>
              <Stats/>
              <SortBy
                items={[
                  { label: 'Relevantes', value: 'productos_erastro' },
                  { label: 'Precio (asc)', value: 'productos_price_asc' },
                  { label: 'Precio (desc)', value: 'productos_price_des' }
                ]}
              />
              <Hits hitComponent={({ hit }) => <Producto hit={hit} state={state} />} />
              </NoResultsBoundary>
            </div>
          

        </div>
        <div className="pagination-container">
          <Pagination className="pagination" totalPages={10} />
        </div>
      </InstantSearch>
      <p className='container-p'>Aquí tienes {title}. Explora nuestro catálogo exclusivo de prendas vintage de segunda mano, cuidadosamente seleccionadas para ti. Encuentra inspiración en una amplia variedad de estilos y épocas. Navega por nuestra colección y encuentra piezas únicas que te transportarán a otras décadas. Descubre las mejores ofertas a través de nuestras alertas y sumérgete en la moda retro con nosotros.</p>
    </div>
    );
  }

  if(state===2){
    return (

    <div>
      <InstantSearch indexName="productos_erastro" searchClient={searchClient} routing={routing}>
        <div className="search-container">
            <SearchBox placeholder={"Buscar producto"} className="buscador-principal" />
        </div>

        <div className="container">
            {/* Botón de mostrar filtros */}
            <button className="toggle-filters-button" onClick={toggleFilters}>
              {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            </button>

            {/* Superposición para cerrar filtros */}
            {showFilters && <div className="overlay" onClick={toggleFilters}></div>}

            {/* Contenedor de filtros */}
            <div className={`filter-container ${showFilters ? 'visible' : ''}`}>
              {/* Botón de cerrar filtros (solo visible en móviles) */}
              <button className="close-filters-button" onClick={toggleFilters}>
                ✕ Cerrar
              </button>
              <div className="filter">
                <ClearRefinements translations={{ resetButtonText: 'Borrar filtros' }} />
                <h3>Sexo</h3>
                <RefinementList attribute="sex" />
                <h3>Categorías</h3>
                <HierarchicalMenu limit={10} showMore={true}  showMoreLimit={40}
                  attributes={[
                    "hierarchicalCategories.lvl0",  // Categoría principal (ej. "Camisetas")
                    "hierarchicalCategories.lvl1",  // Subcategoría (ej. "Camisetas > Manga Corta")
                    "hierarchicalCategories.lvl2"   
                  ]}
                />
                <h3>Precio</h3>
                <RangeInput attribute="price" />
                <h3>Talla</h3>
                <RefinementList attribute="size" limit={7} showMore={true} searchable={true} />
                <h3>Estado</h3>
                <RefinementList attribute="condition" limit={5} />
                <h3>Color</h3>
                <RefinementList attribute="color" limit={5} showMore={true} searchable={true} />
                <h3>Marca</h3>
                <RefinementList attribute="brand" limit={5} showMore={true} searchable={true} />
                <h3>Ubicación</h3>
                <RefinementList attribute="city" limit={5} showMore={true} searchable={true} />
              </div>
            </div>

            <div className='productos'>
              <NoResultsBoundary fallback={<NoResults />}>
              <h1>{title}</h1>
              <p> Descubre las mejores oportunidades en tiendas online y ahorra como nunca antes.</p>
              <Compartir></Compartir>
              <AdsenseDisplay dataAdSlot='7231057135'/>
              <Stats/>
              <SortBy
                items={[
                  { label: 'Relevantes', value: 'productos_erastro' },
                  { label: 'Precio (asc)', value: 'productos_price_asc' },
                  { label: 'Precio (desc)', value: 'productos_price_des' }
                ]}
              />
              <Hits hitComponent={({ hit }) => <Producto hit={hit} state={state} />} />
              </NoResultsBoundary>
            </div>
          

        </div>
        <div className="pagination-container">
          <Pagination className="pagination" totalPages={10} />
        </div>
      </InstantSearch>
      <p className='container-p'>Aquí tienes {title}. Explora nuestro catálogo exclusivo de prendas vintage de segunda mano, cuidadosamente seleccionadas para ti. Encuentra inspiración en una amplia variedad de estilos y épocas. Navega por nuestra colección y encuentra piezas únicas que te transportarán a otras décadas. Descubre las mejores ofertas a través de nuestras alertas y sumérgete en la moda retro con nosotros.</p>
    </div>
    );
  }
  if(state===7){
    return ( 
      <div>
        <InstantSearch indexName="productos_erastro" searchClient={searchClient} routing={routing}>
          <div className="search-container">
            <SearchBox placeholder={"Buscar producto"} className="buscador-principal" />
          </div>

          <div className="container">
            {/* Botón de mostrar filtros */}
            <button className="toggle-filters-button" onClick={toggleFilters}>
              {showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}
            </button>

            {/* Superposición para cerrar filtros */}
            {showFilters && <div className="overlay" onClick={toggleFilters}></div>}

            {/* Contenedor de filtros */}
            <div className={`filter-container ${showFilters ? 'visible' : ''}`}>
              {/* Botón de cerrar filtros (solo visible en móviles) */}
              <button className="close-filters-button" onClick={toggleFilters}>
                ✕ Cerrar
              </button>
              <div className="filter">
                <ClearRefinements translations={{ resetButtonText: 'Borrar filtros' }} />
                <h3>Sexo</h3>
                <RefinementList attribute="sex" />
                <h3>Categorías</h3>
                <HierarchicalMenu limit={10} showMore={true}  showMoreLimit={40}
                  attributes={[
                    "hierarchicalCategories.lvl0",  // Categoría principal (ej. "Camisetas")
                    "hierarchicalCategories.lvl1",  // Subcategoría (ej. "Camisetas > Manga Corta")
                    "hierarchicalCategories.lvl2"   
                  ]}
                />
                <h3>Precio</h3>
                <RangeInput attribute="price" />
                <h3>Talla</h3>
                <RefinementList attribute="size" limit={7} showMore={true} searchable={true} />
                <h3>Estado</h3>
                <RefinementList attribute="condition" limit={5} />
                <h3>Color</h3>
                <RefinementList attribute="color" limit={7} showMore={true} searchable={true}/>
                <h3>Marca</h3>
                <RefinementList attribute="brand" limit={8} showMore={true} searchable={true}/>
                <h3>Ubicación</h3>
                <RefinementList attribute="city" limit={7} showMore={true} searchable={true} />
              </div>
            </div>

            <div className="productos">
              <NoResultsBoundary fallback={<NoResults />}>
                <h1>{title}</h1>
                <p> Descubre las mejores oportunidades en tiendas online y ahorra como nunca antes.</p>
                <Compartir />
                <div className="stats-sort">
                  <Stats />
                  <SortBy
                    items={[
                      { label: 'Relevantes', value: 'productos_erastro' },
                      { label: 'Precio (asc)', value: 'productos_price_asc' },
                      { label: 'Precio (desc)', value: 'productos_price_des' },
                    ]}
                  />
                </div>
                <Hits hitComponent={({ hit }) => <Producto hit={hit} state={state} user={currentUser} />} />
              </NoResultsBoundary>
            </div>
          </div>

          <div className="pagination-container">
            <Pagination className="pagination" totalPages={10} />
          </div>
        </InstantSearch>
        <p className="container-p">Aquí tienes {title}. Explora nuestro catálogo exclusivo...</p>
      </div>
    );
  }
  return (
    <AuthProvider 
    onUserLoggIn={handleUserLoggIn}
    onUserNotLoggIn={handleUserNotLoggIn}
    onUserNotRegistered={handleUserNotRegistered}
    onUserPremium={handleUserPremium}
    >
    </AuthProvider>
  )
}

